import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { Formik } from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Immutable from 'immutable';
import * as FormUtils from 'global/utils/form-utils';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Fab from '@material-ui/core/Fab';
import axios from 'global/axios-instance';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { updateSession } from 'global/actions/global-actions';
import { connect } from 'react-redux';
import { logout } from 'global/actions/global-actions';
import Paper from '@material-ui/core/Paper';
import LogoutIcon from '@material-ui/icons/ExitToApp';

const styles = theme => ({
    container: {
        maxWidth: '1200px',
        width: '100%',
        margin: '1rem auto',
        padding: '2rem',
        textAlign: 'center',
        position: 'relative'
    },
    formContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            alignItems: 'center'
        }
    },
    formControl: {
        margin: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            width: '30%'
        }
    },
    errorContainer: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '350px'
    },
    button: {
        textAlign: 'right',
        margin: '1rem'
    },
    logoutButton: {
        position: 'absolute',
        top: '1rem',
        right: '1rem'
    }
});

const QUESTIONNAIRE_FIELDS = [
    'employedRegion',
    'residenceRegion',
    'accessRegion'
];

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        push,
        logout,
        updateSession
    }, dispatch);
}

@withStyles(styles)
@connect(null, mapDispatchToProps)
class LoginQuestionnaire extends React.PureComponent {
    constructor(props) {
        super(props);

        this.currentUser = null;
    }

    state = {
        regions: Immutable.List()
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        //currentUser: PropTypes.object,
        push: PropTypes.func.isRequired,
        updateSession: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired
    }

    async componentDidMount() {
        const userResponse = await axios.get('/auth/session');

        this.currentUser = userResponse.data;

        const regionResponse = await axios.get('/auth/regions');

        this.setState({
            regions: Immutable.fromJS(regionResponse.data)
        });
    }

    onSubmit = async (values) => {
        const payload = FormUtils.cleanupEmptyStrings(_.pick(values, QUESTIONNAIRE_FIELDS));
        const result = await axios.put(`/auth/regions/${this.currentUser.userId}`, payload);
        const { success, user } = result.data;

        await this.props.updateSession({ user });

        if (success) {
            return this.props.push('/');
        } else {
            return this.props.push('/no-regions');
        }
    }

    onLogout = () => {
        this.props.logout();
        window.location.href = '/';
    }

    render() {
        const { classes } = this.props;
        const { regions } = this.state;

        let initialValues = FormUtils.getInitialValues(QUESTIONNAIRE_FIELDS);
        initialValues.employedRegion = '';
        initialValues.residenceRegion = '';
        initialValues.accessRegion = {};

        regions.forEach(r => {
            const region = r.get('awsRegion');
            initialValues.accessRegion[region] = false;
        });

        return (<Paper className={classes.container}>
            <Fab size='medium' variant='extended' className={classes.logoutButton} onClick={this.onLogout}><LogoutIcon/>Logout</Fab>
            <h2>Please answer these questions to continue logging in.</h2>
            <Formik
                ref={this.form}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    this.onSubmit(values)
                        .then(() => setSubmitting(false));
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={classes.formContainer}>
                            <FormControl component='fieldset' className={classes.formControl}>
                                <FormLabel component='legend'>What region are you employed in?</FormLabel>
                                <RadioGroup name='employedRegion' value={values.employedRegion} onBlur={handleBlur} onChange={handleChange} >
                                    {regions.sortBy(r => r.get('awsRegion')).map(r =>
                                        <FormControlLabel
                                            key={r.get('awsRegion')}
                                            value={r.get('awsRegion')}
                                            control={ <Radio/> }
                                            label={r.get('regionName')}
                                        />
                                    )}
                                </RadioGroup>
                                {errors.employedRegion && touched.employedRegion &&
                                    <FormHelperText error>{errors.employedRegion}</FormHelperText>
                                }
                            </FormControl>
                            <FormControl component='fieldset' className={classes.formControl}>
                                <FormLabel component='legend'>What region do you live in?</FormLabel>
                                <RadioGroup name='residenceRegion' value={values.residenceRegion} onBlur={handleBlur} onChange={handleChange} >
                                    {regions.sortBy(r => r.get('awsRegion')).map(r =>
                                        <FormControlLabel
                                            key={r.get('awsRegion')}
                                            value={r.get('awsRegion')}
                                            control={ <Radio/> }
                                            label={r.get('regionName')}
                                        />
                                    )}
                                </RadioGroup>
                                {errors.residenceRegion && touched.residenceRegion &&
                                    <FormHelperText error>{errors.residenceRegion}</FormHelperText>
                                }
                            </FormControl>
                            <FormControl component='fieldset' className={classes.formControl}>
                                <FormLabel component='legend'>What region(s) do you need access to?</FormLabel>
                                <FormGroup>
                                    {regions.sortBy(r => r.get('awsRegion')).map(r =>
                                        <FormControlLabel
                                            key={r.get('awsRegion')}
                                            control={
                                                <Checkbox name={`accessRegion.${r.get('awsRegion')}`}
                                                    checked={values.accessRegion[r.get('awsRegion')]}
                                                    onChange={handleChange} onBlur={handleBlur}
                                                    classes={{ root: classes.denseCheckbox }} />
                                            }
                                            label={r.get('regionName')}
                                        />
                                    )}
                                </FormGroup>
                                {errors.accessRegion && touched.accessRegion &&
                                    <FormHelperText error>{errors.accessRegion}</FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <div className={classes.button} >
                            <Fab color='primary' size='medium' variant='extended' onClick={handleSubmit} disabled={isSubmitting}>
                                <ArrowUpwardIcon />
                                        Submit
                            </Fab>
                        </div>
                    </form>
                )}
            </Formik>
        </Paper>
        );
    }
}

export default LoginQuestionnaire;
