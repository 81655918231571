import _ from 'lodash';

const emptyStringToNull = (value) => {
    if (_.isString(value) && _.isEmpty(value)) {
        return null;
    } else {
        return value;
    }
};

/**
 * Convert all empty string values on this object to null
 * This is useful for controlled forms since a value of null isn't always supported
 */
export function cleanupEmptyStrings(object, omitProps) {
    let obj = _.omit(object, omitProps);
    return _.mapValues(obj, emptyStringToNull);
}

/**
 * Convert all null values on this object to an empty string
 * This is useful for controlled forms since a value of null isn't always supported
 */
export function cleanupNulls(object, omitProps) {
    let obj = _.omit(object, omitProps);
    return _.mapValues(obj, v => v === null ? '' : v);
}

/**
 * Initialize empty values (specifically empty string because react hates null) for an array of field keys
 * @param {Array} keys Array of field names
 */
export function getInitialValues(keys) {
    return _.zipObject(keys, _.map(keys, () => ''));
}
