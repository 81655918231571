import _ from 'lodash';
import rawAxios from 'axios';
import { store } from '../configure-store';
import * as actionTypes from 'global/constants/global-action-types';

let configuredAxios = rawAxios.create({
    baseURL: process.env.API_ROOT
});

let isRefreshing = false;
let refreshSubscribers = [];

function refreshAccessToken() {
    const { jwt, refreshToken } = localStorage;

    if (_.isNil(jwt) || _.isNil(refreshToken)) {
        store.dispatch({
            type: actionTypes.LOGOUT
        });
        location.reload();
        return Promise.reject('JWT or refreshToken is missing');
    }

    return rawAxios.post('/auth/refresh', {
        accessToken: jwt,
        refreshToken
    }, {
        baseURL: process.env.API_ROOT
    }).then(response => {
        store.dispatch({
            type: actionTypes.CONFIGURE_SESSION,
            token: response.data.token,
            refreshToken: response.data.refreshToken,
            user: response.data.user
        });

        return response.data.token;
    },
    err => {
        store.dispatch({
            type: actionTypes.LOGOUT
        });

        isRefreshing = false;

        location.reload();
        return Promise.reject(err);
    });
}

configuredAxios.interceptors.response.use(response => {
    return response;
}, error => {
    const { config, response: { status } } = error;
    const originalRequest = config;

    if (status === 498) {
        if (!isRefreshing) {
            isRefreshing = true;
            refreshAccessToken()
                .then(() => {
                    isRefreshing = false;
                    onRefreshed();
                });
        }

        const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh(() => {
                resolve(configuredAxios(originalRequest));
            });
        });
        return retryOrigReq;
    } else {
        return Promise.reject(error);
    }
});

function subscribeTokenRefresh(cb) {
    refreshSubscribers.push(cb);
}

function onRefreshed() {
    refreshSubscribers.forEach(cb => cb());
}

const AUTHORIZATION_INTERCEPTOR_KEY = 'bearerInterceptor';

const interceptors = {};

export const setAuthorizationToken = (token) => {
    if (Object.prototype.hasOwnProperty.call(interceptors, AUTHORIZATION_INTERCEPTOR_KEY)) {
        const oldInterceptor = interceptors[AUTHORIZATION_INTERCEPTOR_KEY];
        configuredAxios.interceptors.request.eject(oldInterceptor);
    }

    const interceptor = (config) => {
        config.headers.authorization = `Bearer ${token}`;
        return config;
    };

    // Register this interceptor
    interceptors[AUTHORIZATION_INTERCEPTOR_KEY] = configuredAxios.interceptors.request.use(interceptor);
};

export const unsetAuthorizationToken = () => {
    if (Object.prototype.hasOwnProperty.call(interceptors, AUTHORIZATION_INTERCEPTOR_KEY)) {
        const oldInterceptor = interceptors[AUTHORIZATION_INTERCEPTOR_KEY];
        configuredAxios.interceptors.request.eject(oldInterceptor);
        delete interceptors[AUTHORIZATION_INTERCEPTOR_KEY];
    }
};

export default configuredAxios;
