import * as actionTypes from 'global/constants/global-action-types';
import { createReducer } from 'root-reducer';
import { setAuthorizationToken, unsetAuthorizationToken } from 'global/axios-instance';
import _ from 'lodash';

const actions = {
    [actionTypes.CONFIGURE_SESSION]: (state, action) => {
        const { token, refreshToken, user } = action;
        const result = {};

        if (!_.isUndefined(token)) {
            localStorage.jwt = token;
            result.token = token;
            setAuthorizationToken(token);
        }

        if (!_.isUndefined(user)) {
            result.user = user;
        }

        if (!_.isUndefined(refreshToken)) {
            localStorage.refreshToken = refreshToken;
        }

        return {
            ...state,
            ...result
        };
    },
    [actionTypes.LOGOUT]: (state, action) => {
        localStorage.removeItem('jwt');
        localStorage.removeItem('refreshToken');
        unsetAuthorizationToken();
        return initialState();
    }
};

const initialState = () => {
    return {
        token: null,
        user: null
    };
};

export default createReducer(initialState(), actions);
