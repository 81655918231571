import * as actionTypes from 'global/constants/global-action-types';
import { createReducer } from 'root-reducer';

const actions = {
    [actionTypes.POPULATE_SETTINGS]: (state, action) => {
        const { settings } = action;

        return {
            ...state,
            ...settings
        };
    }
};

const initialState = () => {
    return {
        cloudDomain: null
    };
};

export default createReducer(initialState(), actions);
