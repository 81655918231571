import * as actionTypes from '../constants/global-action-types';

export const updateSession = ({ token, user, refreshToken }) => {
    return {
        type: actionTypes.CONFIGURE_SESSION,
        token,
        user,
        refreshToken
    };
};

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    };
};

export const populateSettings = (settings) => {
    return {
        type: actionTypes.POPULATE_SETTINGS,
        settings
    };
};
