const redirectUri = process.env.NODE_ENV === 'production' ? getUriFromDocument() : 'http://localhost:3001';

function getUriFromDocument() {
    return document.location.origin;
}

export const msalConfig = {
    auth: {
        clientId: 'd9a6c163-acf7-4dbb-881c-57edc7f8f40c',
        authority: 'https://login.microsoftonline.com/b223ef84-eb37-44e0-8ef9-6b666a35bdce',
        redirectUri: redirectUri
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to 'true' if you are having issues on IE11 or Edge
    }
};

