import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createRootReducer, connectReducer as _connectReducer } from './root-reducer';

export const history = createBrowserHistory();
export let store;
export default function configureStore(preloadedState) {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history)
            )
        )
    );

    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./root-reducer', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }

    return store;
}

export function connectReducer(name, reducer) {
    return _connectReducer(name, reducer, store);
}
