import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { connectReducer } from 'configure-store';
import sessionReducer from './global/session-reducer';
import SettingsReducer from './global/settings-reducer';
import RootRoutes from './root-routes';

import LookupsReducer from 'signed-in/reducers/lookups-reducer';
import { withStyles } from '@material-ui/core/styles';

// Connect Reducers
// The Lookups reducer should be injected later but I can't get it to work without breaking hot reload
connectReducer('session', sessionReducer);
connectReducer('lookups', LookupsReducer);
connectReducer('settings', SettingsReducer);

const LoadingMessage = () => (
    null
);

// Global style overrides
const styles = theme => ({
    '@global': {
        a: {
            textDecoration: 'none'
        }
    }
});

const App = ({ history }) => {
    return (
        <ConnectedRouter history={history}>
            <Suspense fallback={<LoadingMessage />}>
                <RootRoutes />
            </Suspense>
        </ConnectedRouter>
    );
};

App.propTypes = {
    history: PropTypes.object
};

export default withStyles(styles)(App);
