import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'global/axios-instance';
import { updateSession, populateSettings } from 'global/actions/global-actions';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Login from 'anonymous/views/login';
import AccessPendingError from 'signed-in/views/access-pending-error';
import AccessDeniedError from 'signed-in/views/access-denied-error';
import LoginQuestionnaire from 'signed-in/views/login-questionnaire';

const anonymousRoutes = [];

const signedInRoutes = [
    {
        path: '/',
        component: React.lazy(() => import('signed-in/views/signed-in-root'))
    }
];

function mapStateToProps(state) {
    return {
        token: state.session.token,
        user: state.session.user
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSession,
        populateSettings,
        push
    }, dispatch);
}

function LoginRedirectRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={props =>
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />
            }
        />
    );
}

LoginRedirectRoute.prototype.propTypes = {
    component: PropTypes.node,
    location: PropTypes.object.isRequired
};

@connect(mapStateToProps, mapDispatchToProps)
class RootRoutes extends React.PureComponent {
    static propTypes = {
        token: PropTypes.string,
        user: PropTypes.object,
        updateSession: PropTypes.func.isRequired,
        populateSettings: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired
    }

    state = {
        sessionReady: false
    }

    constructor(props) {
        super(props);

        this.authenticatedRoutes = this.buildRoutes(true, false);
        this.notAuthenticatedRoutes = this.buildRoutes(false, false);
    }

    async componentDidMount() {
        const token = localStorage.jwt;

        const settingsResult = await axios.get('/settings');
        await this.props.populateSettings(settingsResult.data);

        if (token) {
            await this.props.updateSession({ token });

            const result = await axios.get('/auth/session');

            await this.props.updateSession({ user: result.data });
        } else {
            this.routes = this.buildRoutes(false);
            this.setState({ sessionReady: true });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (_.isNil(prevProps.user) && !_.isNil(this.props.user)) {
            this.setState({ sessionReady: true });
        }
        /*if (!_.isNil(prevProps.user) && prevProps.user.isFirstLogin !== this.props.user.isFirstLogin) {
            const result = await axios.get('/auth/session');
            await this.props.updateSession({ user: result.data });
        }*/
    }

    buildRoutes(isAuthenticated) {
        const routes = [];
        routes.push(<Route path='/login' component={Login} key={-1} />);

        routes.push(<Route path='/questionnaire' key={-2}>
            <Redirect to={'/'} />
        </Route>);

        routes.push(<Route path='/access-pending' key={-2}>
            <Redirect to={'/'} />
        </Route>);

        routes.push(<Route path='/no-access' key={-2}>
            <Redirect to={'/'} />
        </Route>);

        routes.push(...anonymousRoutes.map((route, i) =>
            <Route exact path={route.path} key={i} component={route.component} />
        ));

        routes.push(...signedInRoutes.map((route, i) => {
            if (!isAuthenticated) {
                return <LoginRedirectRoute path={route.path} key={i} component={Login} />;
            } else {
                return (<Route path={route.path} key={i} component={route.component} />);
            }
        }));

        return routes;
    }

    render() {
        const { sessionReady } = this.state;
        const { user } = this.props;
        if (!sessionReady) {
            return <CircularProgress />;
        }
        let routes;
        if (!_.isNil(user)) {
            if (!user.accessRequestSubmitted) {
                return <Switch>
                    <Route path='/questionnaire' component={LoginQuestionnaire} key={-3} />
                    <Route key={-2}>
                        <Redirect to={'/questionnaire'} />
                    </Route>
                </Switch>;
            }

            if (user.accessRequestSubmitted && !user.accessApproved) {
                return <Switch>
                    <Route path='/access-pending' component={AccessPendingError} key={-3} />
                    <Route key={-2}>
                        <Redirect to={'/access-pending'} />
                    </Route>
                </Switch>;
            }

            if (user.accessRequestSubmitted && user.accessApproved && user.regions.length === 0) {
                return <Switch>
                    <Route path='/no-access' component={AccessDeniedError} key={-3} />
                    <Route key={-2}>
                        <Redirect to={'/no-access'} />
                    </Route>
                </Switch>;
            }

            routes = this.authenticatedRoutes;
        } else {
            routes = this.notAuthenticatedRoutes;
        }
        return <Switch>{routes}</Switch>;
    }
}

export default RootRoutes;
