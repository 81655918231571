import { datadogRum } from '@datadog/browser-rum';

if (process.env.NODE_ENV === 'production') {
    datadogRum.init({
        applicationId: process.env.RUM_APP_ID,
        clientToken: process.env.RUM_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'jaus',
        env: process.env.RUM_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        version: __BUILD_VERSION__,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'allow',
        allowedTracingOrigins: [process.env.RUM_TRACING_ORIGIN]
    });

    datadogRum.startSessionReplayRecording();
}

// eslint-disable-next-line no-console
console.log(__BUILD_VERSION__);

import './yup-extensions';

import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import configureStore, { history } from './configure-store';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const theme = createMuiTheme({
    typography: {
        useNextVariants: true
    },
    palette: {
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: 'rgba(0, 0, 0, 0.87)'
        },
        warning: {
            light: '#ffb74d',
            main: '#ff9800',
            dark: '#f57c00',
            contrastText: 'rgba(0, 0, 0, 0.87)'
        }
    }
});

const store = configureStore();
const render = () => {
    ReactDOM.render(
    <AppContainer>
        <React.Fragment>
            <CssBaseline/>
            <Provider store={store}>
                <MuiThemeProvider theme={theme}>
                    <MsalProvider instance={msalInstance}>
                        <App history={history} />
                    </MsalProvider>
                </MuiThemeProvider>
            </Provider>
        </React.Fragment>
    </AppContainer>,
    document.getElementById('react-root')
    );
};

render();

// Hot reloading
if (module.hot) {
    // Reload components
    module.hot.accept('./App', () => {
        render();
    });
}
