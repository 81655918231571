import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

let GlobalReducer = { };
export let store;

export function createReducer(initialState, actionsMap) {
    return (state = initialState, action) => {
        const reduceFn = actionsMap[action.type];
        if (!reduceFn) {
            return state;
        }

        return Object.assign({}, state, reduceFn(state, action));
    };
}

export function connectReducer(name, reducer) {
    GlobalReducer[name] = reducer;

    if (!store) {
        return;
    }

    throw new Error('Not yet implemented');
    // Injecting reducers seems to break hot reload. Haven't figured out how to resolve this yet.
    // eslint-disable-next-line no-unreachable
    let newReducer = combineReducers(GlobalReducer);
    store.replaceReducer(newReducer);
}

export const createRootReducer = (history) => {
    GlobalReducer.router = connectRouter(history);
    store = combineReducers(GlobalReducer);
    return store;
};
