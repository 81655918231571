import React from 'react';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { boundMethod } from 'autobind-decorator';
import _ from 'lodash';
import { updateSession } from 'global/actions/global-actions';
import { push } from 'connected-react-router';
import googleLogo from 'assets/google_logo.png';
import laerdalLogo from 'assets/laerdal_logo.png';

import jausLogo from 'assets/jaus_new_200.png';
import Divider from '@material-ui/core/Divider';
import axios from 'global/axios-instance';
import * as Yup from 'yup';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withMsal } from '@azure/msal-react';

const isProduction = process.env.NODE_ENV === 'production';

const schema = Yup.object().shape({
    email: Yup.string()
        .required('Please enter your email')
        .email('Invalid email address'),
    password: Yup.string()
        .required('Please enter your password')
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateSession,
        push
    }, dispatch);
}

const styles = theme => ({
    container: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '350px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    heading: {
        textAlign: 'center'
    },
    textField: {
        margin: theme.spacing(1),
        width: 200
    },
    dense: {
        marginTop: 19
    },
    menu: {
        width: 200
    },
    button: {
        margin: theme.spacing(1)
    },
    sectionHeader: {
        textAlign: 'center',
        margin: '1rem 0 1rem 0',
        fontSize: 14
    },
    divider: {
        width: '100%',
        marginTop: '1rem'
    },
    logo: {
        width: '150px',
        height: '150px'
    },
    googleButton: {
        textTransform: 'none',
        color: '#FFFFFF',
        backgroundColor: '#4c8bf5',
        paddingLeft: '0.1rem',
        paddingTop: '0.12rem',
        paddingBottom: '0.12rem',
        marginBottom: '0.5rem'
    },
    laerdalButton: {
        textTransform: 'none',
        color: '#FFFFFF',
        backgroundColor: '#FF0000',
        paddingLeft: '0.12rem',
        paddingTop: '0.12rem',
        paddingBottom: '0.12rem'
    },
    image: {
        width: '35px',
        height: '33px',
        marginRight: '0.5rem'
    }
});

@withStyles(styles)
@connect(null, mapDispatchToProps)
@withMsal
class Login extends React.PureComponent {
    state = {
        loginError: null
    }

    static propTypes = {
        updateSession: PropTypes.func.isRequired,
        msalContext: PropTypes.object.isRequired,
        push: PropTypes.func.isRequired,
        classes: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired
    }

    @boundMethod
    doSubmit(values, { setSubmitting }) {
        axios.post('/auth/challenge', values)
            .then(response => {
                this.props.updateSession({
                    token: response.data.token,
                    user: response.data.user,
                    refreshToken: response.data.refreshToken
                });
                const user = response.data.user;
                if ( !user.accessRequestSubmitted || user.regions.length === 0) {
                    return this.props.push('/questionnaire');
                }

                this.props.push('/');
            })
            .catch(err => {
                if (err.response.status === 401) {
                    this.setState({
                        loginError: 'Invalid email address or password'
                    });
                } else {
                    this.setState({
                        loginError: 'An unknown error occurred.'
                    });
                }
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    @boundMethod
    async doGoogleLogin() {
        // There's a miniscule chance this isn't loaded by the time the button's clicked. Not
        // if it happens we can move to injecting the gapi code and watching the onLoad function
        window.gapi.auth2.authorize({
            // TODO: Move to an environment variable and serve?
            client_id: __GAPI_CLIENT_ID__,
            scope: 'profile email',
            response_type: 'code',
            hosted_domain: 'blinemedical.com'
        }, async (authorizationResponse) => {
            if (!authorizationResponse.code) {
                // eslint-disable-next-line no-console
                console.error('Code is missing from google auth request. Seems to happen in some very weird situations due to caching: https://stackoverflow.com/a/45761277');
            }
            const responseData = await axios.post('/auth/google', {
                code: authorizationResponse.code
            });

            const { token, user, refreshToken } = responseData.data;

            this.props.updateSession({
                token,
                user,
                refreshToken
            });

            if (_.isNil(this.props.location.state) || _.isNil(this.props.location.state.from)) {
                // Saw this happen once, not sure why
                // eslint-disable-next-line no-console
                console.warn('state.from is empty');
                return this.props.push('/');
            }

            this.props.push(this.props.location.state.from);
        });
    }

    @boundMethod
    async doMicrosoftLogin() {
        const { instance, accounts } = this.props.msalContext;

        const request = {
            scopes: ['User.Read'],
            account: accounts[0]
        };

        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        instance.acquireTokenSilent(request).then(async (response) => {
            this.useTokenResponse(response);
        }).catch(async (e) => {
            //for user's first sign-in
            instance.acquireTokenPopup(request).then(async (response) => {
                this.useTokenResponse(response);
            });
        });
    }

    useTokenResponse = async (response) => {
        let responseData;
        responseData = await axios.post('/auth/microsoft', {
            accessToken: response.accessToken
        });
        const { token, user, refreshToken } = responseData.data;

        this.props.updateSession({
            token,
            user,
            refreshToken
        });

        if (_.isNil(this.props.location.state) || _.isNil(this.props.location.state.from)) {
            return this.props.push('/');
        }

        this.props.push(this.props.location.state.from);
    }

    render() {
        const { classes } = this.props;
        const { loginError } = this.state;
        return (
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={schema}
                onSubmit={this.doSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (
                    <Paper className={classes.container}>
                        <div><img className={classes.logo} src={jausLogo} /></div>
                        <Typography variant='h6' className={classes.sectionHeader}>Sign in with your Laerdal or B-Line Medical e-mail account</Typography>

                        <Button className={classes.googleButton}
                            variant='contained'
                            onClick={this.doGoogleLogin}>
                            <img src={googleLogo} className={classes.image} />
                            Sign in with Google
                        </Button>
                        <Button className={classes.laerdalButton}
                            variant='contained'
                            onClick={this.doMicrosoftLogin}>
                            <img src={laerdalLogo} className={classes.image} />
                            Sign in with Laerdal
                        </Button>

                        {!isProduction &&
                        <React.Fragment>
                            <Divider className={classes.divider} />
                            <form onSubmit={handleSubmit} className={classes.form}>
                                <TextField
                                    error={errors.email && touched.email}
                                    label='Email'
                                    type='email'
                                    name='email'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    className={classes.textField}
                                    helperText={errors.email && touched.email && errors.email}
                                    autoComplete='current-username'
                                />

                                <TextField
                                    error={errors.password && touched.password}
                                    label='Password'
                                    type='password'
                                    name='password'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    className={classes.textField}
                                    helperText={errors.password && touched.password && errors.password}
                                    autoComplete='current-password'
                                />

                                <Button variant='contained' color='primary' className={classes.button}
                                    disabled={isSubmitting} type='submit'>
                                    Submit
                                </Button>

                                {!_.isEmpty(loginError) &&
                                    <FormHelperText error={true}>{loginError}</FormHelperText>
                                }
                            </form>
                        </React.Fragment>}
                    </Paper>
                )}
            </Formik>);
    }
}

export default Login;
