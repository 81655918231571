// TODO: Consolidate these into an NPM package
const yup = require('yup');

// This is regex for a Microsoft "Guid", which is a shittier, more permissive uuid format
const guidRegex = /^([0-9A-Fa-f]{8}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{12})$/;

yup.addMethod(yup.string, 'guid', function (message = '${path} must be a valid guid') {
    return this.test('is-guid', message, value => {
        return value === undefined || value === null ? true : guidRegex.test(value);
    });
});
