import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import jausLogo from 'assets/jaus_new_200.png';
import Fab from '@material-ui/core/Fab';
import { logout } from 'global/actions/global-actions';
import LogoutIcon from '@material-ui/icons/ExitToApp';

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        push,
        logout
    }, dispatch);
}

const styles = theme => ({
    errorContainer: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: '350px'
    },
    logoutButton: {
        position: 'absolute',
        top: '1rem',
        right: '1rem'
    }
});

function mapStateToProps(state) {
    return {
        token: state.session.token,
        user: state.session.user
    };
}

@withStyles(styles)
@connect(mapStateToProps, mapDispatchToProps)
class NoAccessError extends React.PureComponent {
    static propTypes = {
        token: PropTypes.string,
        user: PropTypes.object,
        classes: PropTypes.object,
        push: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired
    }

    onLogout = () => {
        this.props.logout();
        window.location.href = '/';
    }

    render() {
        const { classes } = this.props;
        return <><Fab size='medium' variant='extended' className={classes.logoutButton} onClick={this.onLogout}><LogoutIcon/>Logout</Fab>
            <Paper className={classes.errorContainer}>
                <div><img className={classes.logo} src={jausLogo} /></div>
                <p>This account does not have access to jAUS.</p><p>Please contact <a href='mailto:labsdc_pdmteam@laerdal.com'>labsdc_pdmteam@laerdal.com</a> for additional assistance.</p>
            </Paper></>;
    }
}

export default NoAccessError;
