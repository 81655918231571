import * as actionTypes from '../constants/lookups-action-types';
import { createReducer } from 'root-reducer';
import Immutable from 'immutable';

const actions = {
    [actionTypes.STORE_LOOKUP]: (state, action) => {
        const { key, value } = action;

        return {
            ...state,
            [key]: Immutable.fromJS(value)
        };
    },
    [actionTypes.LOOKUPS_LOADED]: (state, action) => {
        return {
            ...state,
            loaded: action.isLoaded
        };
    }
};

const initialState = () => {
    return {
        loaded: false,
        modules: null
    };
};

export default createReducer(initialState(), actions);
